import styled from "styled-components";
import { SideNav } from "carbon-components-react/lib/components/UIShell";

export const StyledSideNav = styled(SideNav)`
  @media (max-width: 640px) {
    display: none;
  }
  background-image: url("./phone.jpg");
  background-size: cover;
  background-position: top center;
`;
